var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment-list" },
    _vm._l(_vm.comments, function (comment) {
      return _c("Comment", {
        key: comment.commentId,
        attrs: {
          comment: comment,
          postId: _vm.postId,
          socialAccounts: _vm.socialAccounts,
        },
        on: { commentSuccess: _vm.commentSuccess },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }