<template>
  <div>
    <div
      class="flex-row comment"
      style="border-bottom: 2px solid #f5f5f5"
    >
      <img
        :src="comment.profileImage"
        class="headImage f-shrink"
        v-if="comment.profileImage"
      />
      <img
        v-else
        class="headImage f-shrink"
        src="@/assets/images/emailTask/icon_people.png"
      >
      <div class="flex-column f-grow">
        <div class="flex-row-center f-between">
          <span class="comment-name"> {{ comment.username }}</span>
          <span class="comment-time"> {{ timeAgo(comment.created || comment.createTime) }}</span>
        </div>
        <div class="comment-content"> {{ comment.comment }}</div>
        <div
          v-if="socialAccounts.length>0"
          class="comment-recover"
          @click="toggleRecover"
        >{{ $t('publicationStream.reply') }}</div>
        <Comment
          v-if="isRecoverVisible"
          style="padding: 0;margin-top: 6px;"
          :postId="postId"
          :commentId="comment.commentId"
          :socialAccounts="socialAccounts"
          @commentSuccess="commentSuccess"
        />
      </div>
    </div>
    <!-- 递归渲染子评论 -->
    <CommentList
      v-if="comments.length>0"
      :comments="comments"
      :postId="postId"
      :socialAccounts="socialAccounts"
      class="commentList"
    />
  </div>
</template>

<script>
import Comment from "./Comment.vue";
export default {
  components: {
    Comment,
    CommentList: () => import("./CommentList.vue"), // 异步加载子评论列表
  },
  props: {
    comment: Object,
    postId: {
      type: Number,
      default: 0,
    },
    socialAccounts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isRecoverVisible: false, // 默认不显示回复评论
      tempComment: null, // 临时保存回复评论的内容
      comments: [],
    };
  },
  watch: {
    comment: {
      handler(newVal) {
        this.comments = this.getReplyList(newVal.replyListJson);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getReplyList(replyListJson) {
      if (!replyListJson || typeof replyListJson !== "string") {
        return [];
      }
      try {
        return JSON.parse(replyListJson) || [];
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return [];
      }
    },
    toggleRecover() {
      this.isRecoverVisible = !this.isRecoverVisible; // 切换显示状态
    },
    commentSuccess(data) {
      this.isRecoverVisible = false; // 关闭回复评论
      this.comments.unshift(data); // 将新评论添加到子评论列表中
      this.$emit("commentSuccess", data); // 触发父组件刷新评论列表
    },
  },
};
</script>

<style scoped>
.comment {
  width: 100%;
  padding: 10px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 18px;
  text-align: left;
  font-style: normal;
}

.commentList {
  padding-left: 60px;
}

.headImage {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
}

.comment-name {
  font-weight: bold;
}
.comment-time {
  color: rgba(0, 0, 0, 0.4);
}
.comment-content {
  line-height: 14px;
  margin-top: 6px;
}
.comment-recover {
  color: #016fee;
  line-height: 14px;
  margin-top: 6px;
  cursor: pointer;
}
</style>
