var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex-row-center" },
        [
          _c(
            "el-dropdown",
            [
              _c(
                "div",
                { staticClass: "flex-row-center" },
                [
                  _c("CommentHead", {
                    attrs: {
                      url: _vm.currentAccount.userImage,
                      username: _vm.currentAccount.username,
                    },
                  }),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.accounts, function (item) {
                  return _c(
                    "el-dropdown-item",
                    { key: item.id },
                    [
                      _c("CommentHead", {
                        staticClass: "mb10",
                        attrs: { url: item.userImage, username: item.username },
                        on: {
                          click: function ($event) {
                            return _vm.chooseAccount(item)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-input",
        {
          staticClass: "comment flex-column-center mt5",
          attrs: {
            maxlength: "200",
            placeholder: _vm.$t("publicationStream.saySomething"),
          },
          model: {
            value: _vm.message,
            callback: function ($$v) {
              _vm.message = $$v
            },
            expression: "message",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.commentLoading,
                  expression: "commentLoading",
                },
              ],
              staticClass: "comment-button",
              attrs: { slot: "suffix" },
              on: { click: _vm.commentSumit },
              slot: "suffix",
            },
            [_vm._v(_vm._s(_vm.$t("publicationStream.comment")))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }