<template>
  <div class="video-player-message">
    <div class="video-title">{{ message.text }}</div>
    <video
      :src="message.videoUrl"
      controls
      class="video-player mt16"
    ></video>
    <div class="flex-row-center f-between mt16">
      <el-button
        class="gradientBtn"
        type="primary"
        v-if="!message.completed"
        @click="$emit('submit')"
      >{{$t('ai.sure')}}</el-button>
      <el-button
        class="againBtn"
        v-if="!message.completed"
        @click="$emit('again')"
      >{{$t('ai.videoAgain')}}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.video-player-message {
  padding: 10px 8px;
  background: #f3f4f6;
  border-radius: 10px;
  width: 200px;
}
.video-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #293951;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.video-player {
  width: 184px;
  height: 317px;
  // max-height: 300px;
  // border-radius: 8px;
}

.againBtn {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #dcdcdc;
  display: flex;
  justify-content: center;
}
</style>
