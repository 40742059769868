var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-input" },
    [
      _vm.isRecording
        ? _c(
            "div",
            { staticClass: "flex-center f-shrink" },
            [
              _c(
                "el-tooltip",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [_vm._v(_vm._s(_vm.$t("chat.end")))]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1644248055
                  ),
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/video/audio-playing.gif"),
                      alt: "",
                    },
                    on: { click: _vm.toggleRecording },
                  }),
                ]
              ),
              _c("span", { staticClass: "chat-time ml16 mr16" }, [
                _vm._v(" " + _vm._s(_vm.formattedRecordingTime) + " "),
              ]),
              _c(
                "div",
                {
                  staticClass: "flex-row-center cancel-recording",
                  on: { click: _vm.cancelRecording },
                },
                [
                  _c("i", { staticClass: "iconfont icon-guanbi1" }),
                  _vm._v(" " + _vm._s(_vm.$t("chat.cancel")) + " "),
                ]
              ),
            ],
            1
          )
        : _c("el-tooltip", { attrs: { content: _vm.$t("chat.start") } }, [
            _c("i", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isRecording,
                  expression: "isRecording",
                },
              ],
              staticClass: "iconfont icon-huatong mr16",
              class: { "disabled-icon": _vm.disabled },
              on: { click: _vm.toggleRecording },
            }),
          ]),
      _c("el-input", {
        staticClass: "f-grow",
        attrs: {
          type: "textarea",
          maxlength: "200",
          placeholder: _vm.disabled
            ? _vm.$t("ai.workHard")
            : _vm.$t("ai.tipInstruct") + _vm.$t("enter"),
          rows: 1,
          autosize: { minRows: 1, maxRows: 3 },
          disabled: _vm.disabled || _vm.isRecording,
        },
        nativeOn: {
          keydown: function ($event) {
            return _vm.handleKeyDown($event)
          },
        },
        model: {
          value: _vm.message,
          callback: function ($$v) {
            _vm.message = $$v
          },
          expression: "message",
        },
      }),
      _c(
        "div",
        {
          staticClass: "sendMessage f-shrink",
          style: {
            cursor: _vm.disabled || _vm.isRecording ? "not-allowed" : "pointer",
          },
          on: { click: _vm.sendMessage },
        },
        [_c("i", { staticClass: "iconfont icon-telegram-normal" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }