<template>
  <div :class="['chat-message-container', { 'align-right': isUser, 'align-left': !isUser }]">
    <!-- 显示头像 -->
    <img
      v-if="isUser"
      :src="avatar"
      alt="User Avatar"
      class="avatar right-avatar"
    />
    <img
      v-else
      :src="require('@/assets/images/saleAi.png')"
      alt="Other Avatar"
      class="avatar left-avatar"
    />
    <div :class="['chat-content','f-grow', { 'align-right': isUser, 'align-left': !isUser }]">
      <!-- :class="[ isUser && 'fromMessageBox',  !isUser && 'toMessageBox']" -->
      <div
        :class="isUser?'fromMessageBox':'toMessageBox'"
        v-if="message.type === 'text'"
      >
        <div class="fromMessage">
          <div class="fromMessageContent">
            {{ message.content }}
          </div>
          <div
            class="flex-center mt5"
            v-if="message.needSure"
          >
            <el-button
              class="gradientBtn"
              type="primary"
              @click="$emit('submitTheme')"
            >{{$t('ai.sure')}}</el-button>
            <el-button
              class="againBtn"
              @click="$emit('againTheme')"
            >{{$t('ai.themeAgain')}}</el-button>
          </div>
        </div>
      </div>
      <div
        class="chat-message"
        v-else-if="message.type === 'audio'"
      >
        <audio
          controls
          :src="message.url"
          class="f-grow"
          ref="audioPlayer"
        ></audio>
        <el-button
          type="text"
          v-if="message.content && !isShowText"
          @click="isShowText = true"
        >{{ $t('chat.toText') }}</el-button>
        <div v-if="isShowText">{{ message.content }}</div>
      </div>
      <div v-else-if="message.type === 'script'||message.type === 'post'">
        <EditableTextList
          :message="message"
          @submitScript="$emit('submitScript', message)"
          @submitPost="$emit('submitPost', message)"
          @submit="$emit('submitMultiText', message)"
        />
      </div>
      <div v-else-if="message.type === 'video'">
        <VideoPlayerMessage
          :message="message"
          @again="$emit('againVideo', message)"
          @submit="$emit('submitVideo', message)"
        />
      </div>
      <div v-else-if="message.type === 'listVideo'">
        <ListVideo
          :message="message"
          @submit="chooseVideo"
        />
      </div>
      <div v-else-if="message.type === 'listSelector'">
        <ListSelector
          :message="message"
          @submit="submitAccount"
        />
      </div>
      <div :class="['message-time', { 'align-right-time': isUser, 'align-left': !isUser }]">{{ message.time }}</div>
    </div>
  </div>
</template>

<script>
import EditableTextList from "./EditableTextList.vue";
import VideoPlayerMessage from "./VideoPlayerMessage.vue";
import ListSelector from "./ListSelector.vue";
import ListVideo from "./ListVideo.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  components: {
    EditableTextList,
    VideoPlayerMessage,
    ListSelector,
    ListVideo,
  },
  data() {
    return {
      isShowText: false, //是否显示语音转文字
    };
  },
  computed: {
    ...mapGetters(["avatar"]),
    isUser() {
      return this.message.user === "user";
    },
  },
  methods: {
    chooseVideo(url) {
      this.$emit("chooseVideo", this.url);
    },
    submitAccount(selected) {
      this.$emit("submitAccount", selected);
    },
  },
};
</script>

<style scoped>
.chat-message-container {
  /* width: 90%; */
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}
.chat-content {
  position: relative;
}
.align-left {
  text-align: left;
  flex-direction: row;
}

.align-right {
  text-align: right;
  /* margin-left: auto; */
  flex-direction: row-reverse;
}

.align-right-time {
  display: flex;
  justify-content: flex-end;
}

.message-time {
  margin-top: 10px;
  margin-left: 5px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 12px;
  color: #99aeca;
  line-height: 16px;
  text-align: left;
  font-style: normal;
}

.chat-message {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.fromMessageBox {
  width: 100%;
  display: flex;
  justify-content: end;
}

.toMessageBox {
  width: 100%;
  display: flex;
  justify-content: start;
}
.fromMessage {
  max-width: 90%;
}
.fromMessageContent {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 10px 12px;
  word-wrap: break-word;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  font-style: normal;
}
/* 头像样式 */
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  /* border-radius: 50%; */
  margin-right: 10px;
  flex-shrink: 0;
}

.left-avatar {
  margin-right: 8px;
}

.right-avatar {
  margin-left: 8px;
}
</style>
