<template>
  <div class="list-selector">
    <div class="topTitle">
      <div class="selector-title">{{ showSelect ? message.text : $t('selectAccount.choose')  }}</div>
      <el-input
        v-if="showSelect"
        :placeholder="$t('selectAccount.searchPlaceholder')"
        v-model="searchValue"
        class="input-with-select"
      >
        <el-select
          v-model="platform"
          slot="prepend"
          :placeholder="$t('tip.select')"
          style="width: 100px;"
        >
          <el-option
            :label="$t('selectAccount.allPlatforms')"
            value=""
          ></el-option>
          <el-option
            v-for="dict in dict.type.social_platform"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
        <i
          slot="append"
          class="iconfont icon-sousuotubiao"
          @click="searchAccount"
        ></i>
      </el-input>
    </div>
    <div
      class="mediaList"
      v-if="showSelect"
    >
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in socialPlatformList"
          :key="index"
          :name="item.name"
        >
          <div
            slot="label"
            class="tabItem"
          >
            <i :class="['iconfont', item.icon]"></i>
            <span
              v-if="activeName === item.name"
              class="mediaLabel"
            >{{ item.label }}</span>
          </div>
          <div
            class="tabContent"
            v-loading="loading"
          >
            <div
              class="boxItem"
              v-for="item in options"
              :key="item.name"
              @click="toggleSelect(item.id)"
            >
              <div class="leftTitle">
                <el-avatar
                  :size="24"
                  :src="item.userImage"
                ></el-avatar>
                <div
                  class="mediaName"
                  :class="{'mediaName-active': item.isSelected}"
                >{{ item.username }}</div>
              </div>
              <div :class="['selectable-button', { selected: item.isSelected }]">
                <div
                  v-if="item.isSelected"
                  class="checkmark"
                ><i class="iconfont icon-dagou"></i></div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div
      class="selectList"
      v-if="filterSelectList().length"
    >
      <div class="selectNum">
        {{ $t('selectAccount.selectedAccounts') }}： <span>{{ filterSelectList().length }}</span>
      </div>
      <div class="selectListContent">
        <div
          class="boxItem"
          v-for="(item, index) in filterSelectList()"
          :key="index"
        >
          <div class="leftTitle">
            <el-avatar
              :size="24"
              :src="item.userImage"
            ></el-avatar>
            <div class="mediaName">{{ item.username }}(<i :class="['iconfont', filterIcon(item.platform)]"></i>)</div>
            <div
              class="cancelBox"
              @click="toggleSelect(item.id)"
            >x</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="selectList"
      v-if="submitList.length"
    >
      <div class="selectNum">
        {{ $t('selectAccount.selectedAccounts') }}: <span>{{ submitList.length }}</span>
      </div>
      <div class="selectListContent">
        <div
          class="boxItem"
          v-for="(item, index) in submitList"
          :key="index"
        >
          <div class="leftTitle">
            <el-avatar
              :size="24"
              :src="item.userImage"
            ></el-avatar>
            <div class="mediaName">{{ item.username }}(<i :class="['iconfont', filterIcon(item.platform)]"></i>)</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flexCenter">
      <el-button
        class="gradientBtn"
        type="primary"
        v-if="!message.completed"
        @click="submit"
      >{{$t('ai.sure')}}</el-button>
    </div>
  </div>
</template>

<script>
import EventBus from "../../utils/EventBus";
import { listAllAccount } from "../../api/account/tiktok";
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  dicts: ["social_platform"],
  data() {
    return {
      selected: this.message.selected || [],
      showSelect: true,
      platform: "",
      searchValue: "",
      activeName: "all",
      isSelected: false, // 初始状态为未选中
      // socialPlatformList: [
      //   {
      //     name: 'all',
      //     label: '全部平台',
      //     icon: 'icon-quanbuzhanghao'
      //   },
      //   {
      //     name: 'youtube',
      //     label: 'youtube',
      //     icon: 'icon-youtube-normal'
      //   },
      //   {
      //     name: 'tiktok',
      //     label: 'tiktok',
      //     icon: 'icon-tiktok-normal'
      //   },
      //   {
      //     name: 'facebook',
      //     label: 'facebook',
      //     icon: 'icon-facebook-normal'
      //   },
      //   {
      //     name: 'linkedin',
      //     label: 'linkedin',
      //     icon: 'icon-linkedin-normal'
      //   },
      //   {
      //     name: 'twitter',
      //     label: 'twitter',
      //     icon: 'icon-twitter-normal'
      //   },
      //   {
      //     name: 'instagram',
      //     label: 'instagram',
      //     icon: 'icon-instagram-normal'
      //   },
      //   {
      //     name: 'reddit',
      //     label: 'reddit',
      //     icon: 'icon-reddit-normal'
      //   },
      //   {
      //     name: 'gmb',
      //     label: 'google',
      //     icon: 'icon-gmb-normal'
      //   },
      //   {
      //     name: 'pinterest',
      //     label: 'pinterest',
      //     icon: 'icon-pinterest-normal'
      //   },
      //   {
      //     name: 'telegram',
      //     label: 'telegram',
      //     icon: 'icon-telegram-normal'
      //   },
      // ],
      options: [],
      oldOptions: [],
      submitList: [],
      loading: false,
    };
  },
  computed: {
    socialPlatformList() {
      const platformList = this.dict.type.social_platform.map((type) => ({
        name: type.value,
        label: type.label,
        icon: "icon-" + type.value + "-normal",
      }));
      platformList.unshift({
        name: "all",
        label: this.$t("selectAccount.allPlatforms"),
        icon: "icon-quanbuzhanghao",
      });
      return platformList;
    },
  },
  watch: {
    message: {
      handler(newVal) {
        this.options = JSON.parse(JSON.stringify(newVal.options));
        this.oldOptions = JSON.parse(JSON.stringify(newVal.options));
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    submit() {
      if (this.filterSelectList().length === 0) {
        this.$modal.msgError(this.$t("ai.socialChooseFirst"));
      } else {
        this.submitList = this.filterSelectList();
        const newIdList = this.filterSelectList().map((item) => item.id);
        this.$emit("submit", JSON.stringify(newIdList));
        this.showSelect = false;
      }
    },
    toggleSelect(id) {
      this.oldOptions.forEach((item) => {
        if (item.id === id) {
          item.isSelected = !item.isSelected;
        }
      });
      this.options.forEach((item) => {
        if (item.id === id) {
          item.isSelected = !item.isSelected; // 切换选中状态
        }
      });
    },
    filterSelectList() {
      if (this.oldOptions.length) {
        return this.oldOptions.filter((item) => item.isSelected === true);
      } else {
        return false;
      }
    },
    filterIcon(name) {
      let icon;
      switch (name) {
        case "youtube":
          icon = "icon-youtube-normal";
          break;
        case "tiktok":
          icon = "icon-tiktok-normal";
          break;
        case "facebook":
          icon = "icon-facebook-normal";
          break;
        case "linkedin":
          icon = "icon-linkedin-normal";
          break;
        case "twitter":
          icon = "icon-twitter-normal";
          break;
        case "instagram":
          icon = "icon-instagram-normal";
          break;
        case "reddit":
          icon = "icon-reddit-normal";
          break;
        case "gmb":
          icon = "icon-gmb-normal";
          break;
        case "pinterest":
          icon = "icon-pinterest-normal";
          break;
        case "telegram":
          icon = "icon-telegram-normal";
          break;
        default:
          icon = "icon-quanbuzhanghao";
          break;
      }
      return icon;
    },
    handleClick(tab, event) {
      this.getList({ platform: tab.name === "all" ? "" : tab.name });
      // EventBus.$emit('search-media', {platform: tab.name === 'all' ? '' : tab.name, selectList: this.filterSelectList().length ? this.filterSelectList() : '' })
    },
    searchAccount() {
      this.getList({ platform: this.platform, searchValue: this.searchValue });
      // EventBus.$emit('search-mediaAccount', {platform: this.platform, searchValue: this.searchValue, selectList: this.filterSelectList().length && this.filterSelectList()})
    },
    getList(searchObj) {
      this.loading = true;
      listAllAccount(searchObj)
        .then((response) => {
          const newList = response.data.map((item) => {
            if (this.filterSelectList().length) {
              return {
                ...item,
                isSelected: this.filterSelectList().some(
                  (selectItem) => selectItem.id === item.id
                ),
              };
            } else {
              return { ...item, isSelected: false };
            }
          });
          this.options = newList;
        })
        .finally((e) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-selector {
  padding: 10px 16px 16px 8px;
  background: #f3f4f6;
  border-radius: 10px;
}
.topTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .selector-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #293951;
    line-height: 20px;
    text-align: left;
    font-style: normal;
  }
  .input-with-select {
    width: 400px;
    height: 32px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    ::v-deep .el-input-group__append,
    ::v-deep .el-input-group__prepend {
      background: #ffffff;
      border-radius: 8px;
      border: none;
    }
  }
  .el-input-group > ::v-deep .el-input__inner {
    border-top: none;
    border-bottom: none;
    border-right: none;
  }
  .icon-sousuotubiao {
    cursor: pointer;
    font-size: 16px;
    background: linear-gradient(90deg, #0089ff 0%, #6e16d1 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.mediaList {
  height: 436px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  padding: 0 10px;
  .el-tabs__nav-scroll {
    padding: 0 18px;
  }
  .tabItem {
    display: flex;
    align-items: center;
    .iconfont {
      font-size: 16px;
      margin-right: 8px;
    }
    .mediaLabel {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 22px;
      text-align: left;
      font-style: normal;
      // margin-left: 8px;
    }
  }
  .tabContent {
    padding: 0 8px 20px 0;
    display: flex;
    flex-wrap: wrap; /* 允许内容换行 */
    flex-direction: column; /* 从上到下排列 */
    height: 350px; /* 容器高度，控制列的高度 */
    width: 100%; /* 容器宽度 */
    overflow-x: auto; /* 横向滚动条 */
    .boxItem {
      width: 180px; /* 小盒子宽度 */
      height: 30px; /* 小盒子高度 */
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 40px;
      cursor: pointer;
      .leftTitle {
        width: 160px;
        display: flex;
        align-items: center;
        .mediaName {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #293951;
          line-height: 18px;
          text-align: left;
          font-style: normal;
          width: 125px;
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .mediaName-active {
          font-weight: bold;
          color: #016fee;
        }
      }
      .selectable-button {
        width: 14px;
        height: 14px;
        background: #ffffff;
        border: 1px solid #bbbaba;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s, border 0.3s;
      }

      .selectable-button.selected {
        background-color: #016fee; /* 选中状态的背景色 */
        border: 1px solid #016fee; /* 选中状态的边框 */
      }

      .checkmark {
        color: white;
        .icon-dagou {
          font-size: 10px;
        }
      }
    }
  }
}

.flexCenter {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.icon-quanbuzhanghao {
  color: #0156ff !important;
}
.icon-youtube-normal {
  color: #db0200;
}
.icon-tiktok-normal {
  background: #333333;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon-facebook-normal {
  color: #1a77f3;
}
.icon-linkedin-normal {
  color: #0474b3;
}
.icon-twitter-normal {
  color: #004da5;
}
.icon-instagram-normal {
  background: linear-gradient(221deg, #d82a81 0%, #ffcb5c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon-reddit-normal {
  font-size: 19px;
  color: #ff4500;
}
.icon-gmb-normal {
  color: #0096ff;
}
.icon-pinterest-normal {
  color: #cc1f28;
}
.icon-telegram-normal {
  color: #0072ff;
}

.selectList {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  margin-top: 8px;
  padding: 16px 8px;
  .selectNum {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #293951;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    // margin: 10px 5px;
    margin-bottom: 13px;
    span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #0156ff;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
  }

  .selectListContent {
    display: flex;
    flex-wrap: wrap;
    .boxItem {
      // width: 180px;           /* 小盒子宽度 */
      height: 30px; /* 小盒子高度 */
      margin: 0 24px 8px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftTitle {
        // width: 160px;
        display: flex;
        align-items: center;
        position: relative;
        .mediaName {
          // width: 125px;
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #293951;
          line-height: 18px;
          text-align: left;
          font-style: normal;
        }
        .cancelBox {
          position: absolute;
          right: -5px;
          top: -2px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          color: #fff;
          background: #bdbdbd;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .iconfont {
        font-size: 12px;
      }
    }
  }
}
</style>
