var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "flex-row comment",
          staticStyle: { "border-bottom": "2px solid #f5f5f5" },
        },
        [
          _vm.comment.profileImage
            ? _c("img", {
                staticClass: "headImage f-shrink",
                attrs: { src: _vm.comment.profileImage },
              })
            : _c("img", {
                staticClass: "headImage f-shrink",
                attrs: {
                  src: require("@/assets/images/emailTask/icon_people.png"),
                },
              }),
          _c(
            "div",
            { staticClass: "flex-column f-grow" },
            [
              _c("div", { staticClass: "flex-row-center f-between" }, [
                _c("span", { staticClass: "comment-name" }, [
                  _vm._v(" " + _vm._s(_vm.comment.username)),
                ]),
                _c("span", { staticClass: "comment-time" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.timeAgo(
                          _vm.comment.created || _vm.comment.createTime
                        )
                      )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "comment-content" }, [
                _vm._v(" " + _vm._s(_vm.comment.comment)),
              ]),
              _vm.socialAccounts.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "comment-recover",
                      on: { click: _vm.toggleRecover },
                    },
                    [_vm._v(_vm._s(_vm.$t("publicationStream.reply")))]
                  )
                : _vm._e(),
              _vm.isRecoverVisible
                ? _c("Comment", {
                    staticStyle: { padding: "0", "margin-top": "6px" },
                    attrs: {
                      postId: _vm.postId,
                      commentId: _vm.comment.commentId,
                      socialAccounts: _vm.socialAccounts,
                    },
                    on: { commentSuccess: _vm.commentSuccess },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.comments.length > 0
        ? _c("CommentList", {
            staticClass: "commentList",
            attrs: {
              comments: _vm.comments,
              postId: _vm.postId,
              socialAccounts: _vm.socialAccounts,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }