<template>
  <div class="comment-list">
    <Comment
      v-for="comment in comments"
      :key="comment.commentId"
      :comment="comment"
      :postId="postId"
      :socialAccounts="socialAccounts"
      @commentSuccess="commentSuccess"
    />
  </div>
</template>
<script>
import Comment from "./index.vue";

export default {
  components: {
    Comment,
  },
  props: {
    comments: Array,
    postId: {
      type: Number,
      default: 0,
    },
    socialAccounts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    commentSuccess(data) {
      this.$emit("commentSuccess", data); // 触发父组件刷新评论列表
    },
  },
};
</script>
<style scoped>
</style>
