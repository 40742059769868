<template>
  <div>
    <div class="flex-row-center">
      <el-dropdown>
        <div class="flex-row-center">
          <CommentHead
            :url="currentAccount.userImage"
            :username="currentAccount.username"
          ></CommentHead>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in accounts"
            :key="item.id"
          >
            <CommentHead
              :url="item.userImage"
              :username="item.username"
              class="mb10"
              @click="chooseAccount(item)"
            ></CommentHead>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-input
      class="comment flex-column-center mt5"
      v-model="message"
      maxlength="200"
      :placeholder="$t('publicationStream.saySomething')"
    >
      <div
        v-loading="commentLoading"
        slot="suffix"
        class="comment-button"
        @click="commentSumit"
      >{{ $t('publicationStream.comment') }}</div>
    </el-input>
  </div>
</template>
<script>
import { pubComments } from "../../api/ai/publish";
import CommentHead from "./CommentHead";

export default {
  components: {
    CommentHead,
  },
  props: {
    socialAccounts: {
      type: Array,
      default: () => [],
    },
    //发布记录的id
    postId: {
      type: Number,
      default: 0,
    },
    //评论接口的commentId
    commentId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      message: "",
      commentLoading: false,
      accounts: this.socialAccounts,
    };
  },
  watch: {
    socialAccounts: {
      handler(val) {
        this.accounts = val;
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    currentAccount() {
      return (
        this.accounts.find((item) => item.isSelected === true) ||
        this.accounts[0] ||
        {}
      );
    },
  },
  methods: {
    chooseAccount(item) {
      console.log("item-------");
      // 取消所有其他项目的选择并选择当前项目
      this.accounts = this.accounts.map((v) => ({
        ...v,
        isSelected: v.id === item.id, // 只有点击的项目会被选中
      }));
    },
    commentSumit() {
      if (!this.message) {
        this.$modal.msgError(this.$t('publicationStream.commentTip'));
        return;
      }
      this.commentLoading = true;
      pubComments({
        postId: this.postId,
        commentId: this.parentCommentId || this.commentId,
        comment: this.message,
        socialId: this.currentAccount.id,
      })
        .then((res) => {
          this.$modal.msgSuccess(this.$t('publicationStream.commentSuccess'));
          this.$emit("commentSuccess", {
            profileImage: this.currentAccount.userImage,
            username: this.currentAccount.username,
            createTime: new Date(),
            comment: this.message,
            commentId: this.commentId,
          });
          this.message = "";
        })
        .finally(() => {
          this.commentLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.comment {
  padding: 0px;
  background: #f4f4f5;
  border-radius: 16px;
}
.comment ::v-deep.el-input__inner {
  border: 0px;
  background-color: transparent;
}
.comment ::v-deep.el-input--medium {
  background: #f4f4f5;
  border-radius: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.comment-button {
  height: 24px;
  background: #0156ff;
  border-radius: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  padding: 1px 16px;
  cursor: pointer;
}
::v-deep .el-input__suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
