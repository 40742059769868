<template>
  <div
    class="flex-row-center"
    @click="$emit('click')"
  >
    <img
      v-if="url"
      :src="url"
      class="headImage f-shrink"
    />
    <img
      v-else
      class="headImage f-shrink"
      src="@/assets/images/emailTask/icon_people.png"
    >
    <span class="username"> {{ username }}</span>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    username: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.headImage {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
}
.username {
  height: 18px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 18px;
  text-align: left;
  font-style: normal;
}
</style>
