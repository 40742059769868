var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex-row-center",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.url
        ? _c("img", {
            staticClass: "headImage f-shrink",
            attrs: { src: _vm.url },
          })
        : _c("img", {
            staticClass: "headImage f-shrink",
            attrs: {
              src: require("@/assets/images/emailTask/icon_people.png"),
            },
          }),
      _c("span", { staticClass: "username" }, [
        _vm._v(" " + _vm._s(_vm.username)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }