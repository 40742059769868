<template>
  <div class="editable-text-list">
    <div class="editable-msg">{{ message.text }}</div>
    <div v-if="message.type === 'script'">
      <div
        v-for="(item, index) in contents"
        :key="index"
      >
        <div class="inputItem">
          <div class="leftAutoTextarea flex-row">
            <div class="numberText">{{ (index + 1).toString().padStart(2, '0') }}</div>
            <el-input
              :ref="`leftInput`"
              @blur="focusInput('left', index)"
              maxlength="200"
              :disabled="item.leftDisabled"
              resize="none"
              autosize
              type="textarea"
              v-model="item.title"
              :readonly="message.completed"
              @input="updateTitle(index, $event)"
            />
            <i
              class="iconfont icon-edit"
              @click="clickEdit('left', index)"
              v-if="!message.completed"
            ></i>
          </div>
          <div class="autoTextarea">
            <el-input
              :ref="`rightInput`"
              @blur="focusInput('right', index)"
              maxlength="200"
              :disabled="item.rightDisabled"
              resize="none"
              autosize
              type="textarea"
              v-model="item.description"
              :readonly="message.completed"
              @input="updateContent(index, $event)"
            />
            <i
              class="iconfont icon-edit"
              @click="clickEdit('right', index)"
              v-if="!message.completed"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="postMessage"
      v-if="message.type === 'post'"
    >
      <div class="topTextarea">
        <div class="title">{{ $t('chat.textTitle') }}:</div>
        <el-input
          :ref="`topInput`"
          @blur="focusInput('top')"
          maxlength="200"
          :disabled="postTopDisabled"
          resize="none"
          autosize
          type="textarea"
          v-model="message.titles"
          :readonly="message.completed"
        />
        <i
          class="iconfont icon-edit"
          @click="clickEdit('top')"
        ></i>
      </div>
      <div class="line"></div>
      <div class="topTextarea">
        <div class="title">{{ $t('chat.textContent') }}:</div>
        <el-input
          :ref="`bottomInput`"
          @blur="focusInput('bottom')"
          maxlength="200"
          :disabled="postBottomDisabled"
          resize="none"
          autosize
          type="textarea"
          v-model="message.contents"
          :readonly="message.completed"
        />
        <i
          class="iconfont icon-edit"
          @click="clickEdit('bottom')"
        ></i>
      </div>
    </div>
    <!-- <div class="list-container">
      <div class="list">
        <h4>{{$t('ai.title')}}</h4>
        <div class="list-item">
          <el-input type="textarea" :rows="3" :value="item" :readonly="message.completed" @input="updateTitle(index, $event)" />
        </div>
      </div>
      <div class="list">
        <h4>{{$t('ai.content')}}</h4>
        <div v-for="(item, index) in message.contents" :key="index" class="list-item">
          <el-input type="textarea" :rows="3" :value="item" :readonly="message.completed" @input="updateContent(index, $event)" />
        </div>
      </div>
    </div> -->
    <div class="flexCenter">
      <el-button
        class="submitButton"
        type="primary"
        v-if="!message.completed"
        @click="submit"
      >{{$t('ai.sure')}}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      postTopDisabled: true,
      postBottomDisabled: true,
      contents: this.message.type === "script" && [
        ...this.message.contents.map((item) => {
          return {
            ...item,
            leftDisabled: true,
            rightDisabled: true,
          };
        }),
      ],
    };
  },
  methods: {
    updateTitle(index, value) {
      // this.$set(this.message.contents, index, value);
      this.$set(this.message.contents[index], "title", value);
    },
    updateContent(index, value) {
      this.$set(this.message.contents[index], "description", value);
    },
    submit() {
      if (this.message.type == "script") {
        //脚本
        this.$emit("submitScript", this.message);
      } else if (this.message.type == "post") {
        //帖子
        this.$emit("submitPost", this.message);
      } else {
        this.$emit("submit", this.message);
      }
    },
    clickEdit(type, index) {
      if (type === "left") {
        this.contents[index].leftDisabled = false;
        this.$nextTick(() => {
          this.$refs.leftInput[index].$el.querySelector("textarea").focus();
        });
      } else if (type === "right") {
        this.contents[index].rightDisabled = false;
        this.$nextTick(() => {
          this.$refs.rightInput[index].$el.querySelector("textarea").focus();
        });
      } else if (type === "top") {
        this.postTopDisabled = false;
        this.$nextTick(() => {
          this.$refs.topInput.$el.querySelector("textarea").focus();
        });
      } else if (type === "bottom") {
        this.postBottomDisabled = false;
        this.$nextTick(() => {
          this.$refs.bottomInput.$el.querySelector("textarea").focus();
        });
      }
    },
    focusInput(type, index) {
      if (type === "left") {
        this.contents[index].leftDisabled = true;
      } else if (type === "right") {
        this.contents[index].rightDisabled = true;
      } else if (type === "top") {
        this.postTopDisabled = true;
      } else if (type === "bottom") {
        this.postBottomDisabled = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editable-text-list {
  padding: 10px;
  width: 90%;
  background: #f3f4f6;
  border-radius: 10px;
  .editable-msg {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #293951;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-bottom: 16px;
  }
  .inputItem {
    display: flex;
    justify-content: space-between;
    // min-height: 100px;
    align-items: stretch;
    margin-bottom: 8px;

    .leftAutoTextarea {
      width: 35%;
      // display: flex;
      // height: 100%;
      position: relative;
      background: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
      border-radius: 12px;
      border: 1px solid #e5e7eb;
      // padding-right: 40px;
      .numberText {
        font-family: ArialMT;
        font-size: 14px;
        color: #0156ff;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        margin: 8px 0 0 10px;
      }
    }
    .autoTextarea {
      width: 63%;
      position: relative;
      background: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
      border-radius: 12px;
      border: 1px solid #e5e7eb;
    }
    .icon-edit {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: #b0b0b0;
      font-size: 14px;
    }
    ::v-deep .el-textarea__inner {
      height: 100%;
      background: #ffffff;
      border-radius: 12px;
      border: none;
      padding-right: 40px;
      padding: 9px 40px 9px 8px;
      color: #000;
    }

    ::v-deep .el-textarea.is-disabled .el-textarea__inner {
      background-color: #fff;
      border-color: #fff;
      color: rgba(0, 0, 0, 0.6);
      cursor: not-allowed;
    }
  }

  .postMessage {
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e5e7eb;
    margin-bottom: 16px;
    .topTextarea {
      display: flex;
      align-items: center;
      padding: 0 10px;
      position: relative;
      .title {
        font-family: PingFang-SC, PingFang-SC;
        font-weight: bold;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
        text-align: left;
        font-style: normal;
        width: 80px;
      }
      .icon-edit {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: #b0b0b0;
        font-size: 14px;
      }

      .el-textarea {
        width: 100%;
      }
      ::v-deep .el-textarea__inner {
        height: 100%;
        background: #ffffff;
        border-radius: 12px;
        border: none;
        color: #000;
      }

      ::v-deep .el-textarea.is-disabled .el-textarea__inner {
        background-color: #fff;
        border-color: #fff;
        color: rgba(0, 0, 0, 0.6);
        cursor: not-allowed;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #e5e7eb;
    }
  }
}

.list-container {
  display: flex;
  gap: 10px;
}

.list {
  flex: 1;
}

.list-item {
  margin-bottom: 5px;
}

.flexCenter {
  width: 100%;
  display: flex;
  justify-content: center;
  .submitButton {
    width: 80px;
    height: 32px;
    background: linear-gradient(90deg, #0089ff 0%, #6e16d1 100%);
    border-radius: 12px;
    display: flex;
    justify-content: center;
  }
}
</style>
