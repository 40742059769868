var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editable-text-list" }, [
    _c("div", { staticClass: "editable-msg" }, [
      _vm._v(_vm._s(_vm.message.text)),
    ]),
    _vm.message.type === "script"
      ? _c(
          "div",
          _vm._l(_vm.contents, function (item, index) {
            return _c("div", { key: index }, [
              _c("div", { staticClass: "inputItem" }, [
                _c(
                  "div",
                  { staticClass: "leftAutoTextarea flex-row" },
                  [
                    _c("div", { staticClass: "numberText" }, [
                      _vm._v(_vm._s((index + 1).toString().padStart(2, "0"))),
                    ]),
                    _c("el-input", {
                      ref: "leftInput",
                      refInFor: true,
                      attrs: {
                        maxlength: "200",
                        disabled: item.leftDisabled,
                        resize: "none",
                        autosize: "",
                        type: "textarea",
                        readonly: _vm.message.completed,
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.focusInput("left", index)
                        },
                        input: function ($event) {
                          return _vm.updateTitle(index, $event)
                        },
                      },
                      model: {
                        value: item.title,
                        callback: function ($$v) {
                          _vm.$set(item, "title", $$v)
                        },
                        expression: "item.title",
                      },
                    }),
                    !_vm.message.completed
                      ? _c("i", {
                          staticClass: "iconfont icon-edit",
                          on: {
                            click: function ($event) {
                              return _vm.clickEdit("left", index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "autoTextarea" },
                  [
                    _c("el-input", {
                      ref: "rightInput",
                      refInFor: true,
                      attrs: {
                        maxlength: "200",
                        disabled: item.rightDisabled,
                        resize: "none",
                        autosize: "",
                        type: "textarea",
                        readonly: _vm.message.completed,
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.focusInput("right", index)
                        },
                        input: function ($event) {
                          return _vm.updateContent(index, $event)
                        },
                      },
                      model: {
                        value: item.description,
                        callback: function ($$v) {
                          _vm.$set(item, "description", $$v)
                        },
                        expression: "item.description",
                      },
                    }),
                    !_vm.message.completed
                      ? _c("i", {
                          staticClass: "iconfont icon-edit",
                          on: {
                            click: function ($event) {
                              return _vm.clickEdit("right", index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm.message.type === "post"
      ? _c("div", { staticClass: "postMessage" }, [
          _c(
            "div",
            { staticClass: "topTextarea" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("chat.textTitle")) + ":"),
              ]),
              _c("el-input", {
                ref: "topInput",
                attrs: {
                  maxlength: "200",
                  disabled: _vm.postTopDisabled,
                  resize: "none",
                  autosize: "",
                  type: "textarea",
                  readonly: _vm.message.completed,
                },
                on: {
                  blur: function ($event) {
                    return _vm.focusInput("top")
                  },
                },
                model: {
                  value: _vm.message.titles,
                  callback: function ($$v) {
                    _vm.$set(_vm.message, "titles", $$v)
                  },
                  expression: "message.titles",
                },
              }),
              _c("i", {
                staticClass: "iconfont icon-edit",
                on: {
                  click: function ($event) {
                    return _vm.clickEdit("top")
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "line" }),
          _c(
            "div",
            { staticClass: "topTextarea" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("chat.textContent")) + ":"),
              ]),
              _c("el-input", {
                ref: "bottomInput",
                attrs: {
                  maxlength: "200",
                  disabled: _vm.postBottomDisabled,
                  resize: "none",
                  autosize: "",
                  type: "textarea",
                  readonly: _vm.message.completed,
                },
                on: {
                  blur: function ($event) {
                    return _vm.focusInput("bottom")
                  },
                },
                model: {
                  value: _vm.message.contents,
                  callback: function ($$v) {
                    _vm.$set(_vm.message, "contents", $$v)
                  },
                  expression: "message.contents",
                },
              }),
              _c("i", {
                staticClass: "iconfont icon-edit",
                on: {
                  click: function ($event) {
                    return _vm.clickEdit("bottom")
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flexCenter" },
      [
        !_vm.message.completed
          ? _c(
              "el-button",
              {
                staticClass: "submitButton",
                attrs: { type: "primary" },
                on: { click: _vm.submit },
              },
              [_vm._v(_vm._s(_vm.$t("ai.sure")))]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }